* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
}

a {
  color: #333;
  text-decoration: none
}

html, body, #root, .App{
  min-height: 100% !important;
  height: 100%;
  margin: 0;
  padding: 0;
}